function getType(type) {
    type = type.toLowerCase();

    switch (type) {
        case 'artikel':
            type = 'article';
            break;
        case 'video':
            type = 'video';
            break;
        case 'podcast':
            type = 'podcast';
            break;
    }
    return type;
}

function parseNumber(number) {
    const firstDigitIndex = number.search(/\d/);
    const prefix =
        firstDigitIndex !== -1 ? number.slice(0, firstDigitIndex) : number;

    const lastIndex = number.search(/\d(?!.*\d)/);
    const suffix = lastIndex !== -1 ? number.slice(lastIndex + 1) : '';

    const match = number.match(/[.,]\d+/g);
    const decimals = match ? match.map((part) => part.length - 1) : 0;
    const decimal = number.replace(/[^.,]/g, '');

    const numberInt = number.replace(/,/g, '.').replace(/[^0-9.]/g, '');

    return {
        suffix: suffix,
        decimals: decimals,
        decimal: decimal,
        prefix: prefix,
        numberInt: numberInt,
    };
}

function splitArray(array) {
    const midpoint = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, midpoint);
    const secondHalf = array.slice(midpoint);

    return [firstHalf, secondHalf];
}

function throttle(func, limit) {
    let inThrottle;

    return function () {
        const args = arguments;
        const context = this;

        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}

export { getType, parseNumber, splitArray, throttle };
